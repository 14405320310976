import React from 'react'
import Container from 'gatsby-theme-amsterdam/src/components/Container'
import SEO from 'gatsby-theme-amsterdam/src/components/SEO'
import styled from '@emotion/styled'
import { graphql } from 'gatsby'

const Title = styled.h1`
  font-weight: ${props => props.theme.fonts.boldWeight};
  line-height: 1.25;
  max-width: ${props => props.theme.sizes.maxWidthCentered};
  margin: 0 auto 1rem;
  font-size: 2rem;
  @media screen and (min-width: ${props => props.theme.responsive.small}) {
    font-size: 2.5rem;
  }
`

const Content = styled.div`
  margin: 0 auto;
  max-width: ${props => props.theme.sizes.maxWidthCentered};
  h2, h3, h4, h5 {
      font-weight: ${props => props.theme.fonts.boldWeight};
      line-height: 1.25;
      max-width: ${props => props.theme.sizes.maxWidthCentered};
      margin: 0 auto 1rem;

      font-size: 1.8rem;
      @media screen and (min-width: ${props => props.theme.responsive.small}) {
        font-size: 2.1rem;
      }
  }
  p {
    line-height: 1.5;
    margin: 0 0 1.75rem;
    font-size: 1.5rem;
  }
  a {
    transition: 0.3s color;
    color: ${props => props.theme.colors.secondary};
    text-decoration: underline;
    &:hover {
      color: ${props => props.theme.colors.highlight};
    }
    @media (hover: none) {
      color: ${props => props.theme.colors.secondary} !important;
    }
  }
  code {
    font-family: ${props => props.theme.fonts.monospace};
    font-size: 0.9rem;
    padding: 0.25rem;
    background: ${props => props.theme.colors.code};
    color: ${props => props.theme.colors.text};
    border-radius: 0.3em;
  }
  figcaption {
    margin-top: 10px;
    text-align: center;
    font-weight: ${props => props.theme.fonts.boldWeight};
    font-size: 1.2rem;
  }
`

export default function LegalnoticePage({ data }) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark

  return (
      <Container>
        <SEO title="Impressum" description="Impressum der Internetseite Maya's Hundesalon" />
        <Title>{frontmatter.title}</Title>
        <Content>
          <div
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </Content>
      </Container>
  )

}

export const legalnoticePageQuery = graphql`
  query LegalnoticePageQuery {
    markdownRemark( frontmatter: { slug: { eq: "/legalnotice/" } } ) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
      }
    }
  }
`